import React from "react";
import { Link } from "react-static";

export default () => (
  <React.Fragment>
    <div className="hero">
      <img
        sizes="(max-width: 3840px) 100vw, 3840px"
        srcSet="
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_320.jpg 320w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_765.jpg 765w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_1104.jpg 1104w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_1394.jpg 1394w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_1637.jpg 1637w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_1884.jpg 1884w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_2112.jpg 2112w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_2312.jpg 2312w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_2509.jpg 2509w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_2695.jpg 2695w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_2882.jpg 2882w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3041.jpg 3041w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3203.jpg 3203w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3358.jpg 3358w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3504.jpg 3504w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3652.jpg 3652w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3780.jpg 3780w,
            /img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3840.jpg 3840w"
        src="/img/hero_p8ql9g/hero_p8ql9g_c_scale,w_3840.jpg"
        alt="Pozadí stránky - geodeti na stavbě"
      />
      <div className="hero_text">
        <h2>
          <span>Kvalitní geodezie</span>
        </h2>
        <h2>
          <span>za rozumnou cenu</span>
        </h2>
      </div>
    </div>
    <div className="container">
      <div className="row" id="rozcestnik">
        <div id="border-left">
          <h3>Vybrané Služby</h3>
          <div className="col-xs-6">
            <div className="align-left">
              <Link to="/sluzby/geometricky-plan">
                <p>Geometrický plán</p>
              </Link>
              <Link to="/sluzby/zamereni-pozemku">
                <p>Zaměření pozemků</p>
              </Link>
              <Link to="/sluzby/zamereni-bytovych-jednotek">
                <p>Zaměření byt/nebyt. jednotek</p>
              </Link>
            </div>
          </div>
        </div>
        <div id="border-right">
          <div className="col-xs-6">
            <div className="align-left">
              <Link to="/sluzby/zamereni-staveb-stavebni-vykresy">
                <p>Zaměření staveb</p>
              </Link>
              <Link to="/sluzby/zamereni-staveb-stavebni-vykresy">
                <p>Stavební výkresy</p>
              </Link>
              <a href="#">
                <p>Zaměření podkladů pro projekt RD</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12" id="sluzby">
          <Link to="/sluzby">
            <h2>
              Všechny služby <i className="fas fa-angle-right" />
            </h2>
          </Link>
        </div>
      </div>
      <hr />
    </div>
    <div id="color_containers">
      <div className="container">
        <div id="row">
          <div className="col-lg-6 col-md-6">
            <div className="redcontainer">
              <h1>
                {" "}
                Geometrický plán
                <br />
                EXPRESS
              </h1>
              <p>Kolaudace rodinného domu se rychle blíží?</p>
              <Link to="/sluzby/prace-v-katastru-nemovitosti#geometricky-plan-express">
                <h2>
                  Geometrický plán do 2. dne{" "}
                  <i className="fas fa-angle-right" />
                </h2>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="greycontainer">
              <h1> AKCE měsíce</h1>
              <p>
                <b>Sleva 2000,- Kč</b> na balíček geodetických prací rodinného
                domu
              </p>
              <Link to="/slevy/geodeti-cena-geometricky-plan">
                <h2>
                  Více o slevě <i className="fas fa-angle-right" />
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <hr />
      <div className="row" id="ohlasy">
        <div className="col-lg-12">
          <h2>Vaše ohlasy mluví za vše</h2>
          <p>
            “Děkujeme za profesionální a rychlé vypracování geometrického plánu
            na naši novostavbu. Vaši společnost Foltánek s.r.o. ráda doporučím
            svým přátelům. Veškerá dokumentace je tímto kompletní a konečně
            bydlíme. Děkuji.”
            <br />
            <br />- Klára Ramsden -
          </p>
        </div>
      </div>
    </div>
  </React.Fragment>
);
